// import * as msal from "@azure/msal-browser";
// import { REDIRECT_URL_LOCAL, REDIRECT_URL_WEB } from "./mainpathforredirect";

// const isLocalEnvironment = window.location.hostname === "localhost";
// const redirectUri = isLocalEnvironment ? REDIRECT_URL_LOCAL : REDIRECT_URL_WEB;

// const authConfig = {
//   auth: {
//     clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
//     redirectUri:`${process.env.REACT_APP_REDIRECT_URI}`,
//     authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
//   },
//   cache: {
//     cacheLocation: "localStorage",
//     storeAuthStateInCookie: true,
//   },
// };

// const msalApp = new msal.PublicClientApplication(authConfig);

// function refreshnewToken() {
//   return new Promise((resolve, reject) => {
//     try {
//       const accounts = msalApp.getAllAccounts();

//       if (accounts.length === 0) {
//         throw new Error("No accounts found");
//       }

//       const silentRequest = {
//         account: accounts[0],
//       };

//       msalApp
//         .acquireTokenSilent(silentRequest)
//         .then((response) => {
//           const idToken = response.idToken;
//           localStorage.removeItem("auth_token");
//           localStorage.setItem("auth_token", response.idToken);
//           resolve(idToken);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     } catch (error) {
//       reject(error);
//     }
//   });
// }

// const isTokenExpired = async (token) => {
//   // Parse the token to get the expiry date
//   token = token ? token : localStorage.getItem("auth_token");

//   const { exp } = JSON.parse(atob(token.split(".")[1]));

//   // Convert the expiry date to a Date object
//   const expiryDate = new Date(exp * 1000);

//   // Get the current date
//   const now = new Date();

//   // Check if the token is expired
//   if (now > expiryDate) {
//     try {
//       await refreshnewToken();
//       window.location.reload();
//     } catch (error) {
//       console.error("Token refresh failed:", error);
//       localStorage.removeItem("auth_token");
//       localStorage.removeItem("office");
//       localStorage.removeItem("id");
//       localStorage.removeItem("email");
//       localStorage.removeItem("name");
//       localStorage.removeItem("access_token");
//       window.location.href = "/auth/login";
//     }
//   }
// };

// export default isTokenExpired;

import * as msal from "@azure/msal-browser";
import { REDIRECT_URL_LOCAL, REDIRECT_URL_WEB } from "./mainpathforredirect";

const isLocalEnvironment = window.location.hostname === "localhost";
const redirectUri = isLocalEnvironment ? REDIRECT_URL_LOCAL : REDIRECT_URL_WEB;

const authConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_CLIENT_ID}`,
    redirectUri:'https://system.kangina.global/auth/login',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const msalApp = new msal.PublicClientApplication(authConfig);

function refreshnewToken() {
  return new Promise((resolve, reject) => {
    try {
      const accounts = msalApp.getAllAccounts();

      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }

      const silentRequest = {
        account: accounts[0],
      };

      msalApp
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          const idToken = response.idToken;
          localStorage.removeItem("auth_token");
          localStorage.setItem("auth_token", response.idToken);
          resolve(idToken);
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
}

const isTokenExpired = async (token) => {
  // Parse the token to get the expiry date
  token = token ? token : localStorage.getItem("auth_token");

  const { exp } = JSON.parse(atob(token.split(".")[1]));

  // Convert the expiry date to a Date object
  const expiryDate = new Date(exp * 1000);

  // Get the current date
  const now = new Date();

  // Check if the token is expired
  if (now > expiryDate) {
    try {
      await refreshnewToken();
      window.location.reload();
    } catch (error) {
      console.error("Token refresh failed:", error);
      localStorage.removeItem("auth_token");
      localStorage.removeItem("office");
      localStorage.removeItem("id");
      localStorage.removeItem("email");
      localStorage.removeItem("name");
      localStorage.removeItem("access_token");
      window.location.href = "/auth/login";
    }
  }
};

export default isTokenExpired;